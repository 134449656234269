import logo from './logo.svg';
import './App.css';
import FormularioCreche from './FormularioCreche';

function App() {
  return (
   <FormularioCreche/>
  );
}

export default App;
