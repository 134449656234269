import React, { useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Button, Typography, Paper, Box } from '@mui/material';

const FormularioCreche = () => {
  const [formData, setFormData] = useState({
    nomeCrianca: '',
    dataNascimentoCrianca: '',
    nomeMae: '',
    dataNascimentoMae: '',
    nomePai: '',
    crecheDesejada: '',
    irmaoMatriculado: '',
    criancaDeficiencia: '',
    tipoDeficiencia: '',
    laudoDeficiencia: '',
    nomeResponsavel: '',
    grauParentesco: '',
    dataNascimentoResponsavel: '',
    endereco: '',
    bairro: '',
    telefone: '',
    bolsaFamilia: '',
    rendaFamiliar: '',
    residenciaCrianca: '',
    pessoasCasa: '',
    situacaoPais: '',
    veracidadeInformacoes: false,
  });

  const ip = '18.228.225.187';
  const port = '75';

  const [isInit, setIsInit] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [isInsErr, setisInsErr] = useState(false);
  const [isInsOK, setisInsOK] = useState(false);
  const [respCod, setrespCod] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });


  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const data_ = JSON.stringify(formData);
    try {
      const response = await fetch('https://m4ut0j7mz2.execute-api.sa-east-1.amazonaws.com/fc_putForm/' + JSON.stringify(formData));

      if (response.ok) {
        const data = await response.text();
        if (data.trim() == 'Err') {
          setisInsErr(true);
        } else {
          setrespCod(data);
          setisInsOK(true)
        }
      }
      else {
        console.error('Erro ao atualizar dados:', response.statusText);

      }
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
    }
  };

  const handleValidation = () => {
    if (formData.nomeCrianca && formData.nomeMae && formData.crecheDesejada) {
      setIsInit(false);
      //#region VERIFICA DADOS MAE E FILHO
      const fetchData = async () => {
        try {
          const response = await fetch('https://m4ut0j7mz2.execute-api.sa-east-1.amazonaws.com/fc_verificaDados/' + formData.nomeCrianca + '_' + formData.nomeMae);
          if (response.ok) {
            const data = await response.text();
            if (data.trim() === 'true') {
              setIsValidated(true);
            } else {
              setIsValidated(false);
            }
          } else {
            setIsValidated(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsValidated(false);
        }
      };
      fetchData();
    }
  };


  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>

      {!isInsOK && !isInsErr && (
        <form onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'Column' }}>
          <Typography variant="h2" textAlign={'center'}>Cadastro para creche 2025</Typography>
          <Typography variant="h5" textAlign={'center'}>Prefeitura Municipal de Benvides</Typography>
          <Typography variant="h5" textAlign={'center'}>Secretaria Municipal de Educação - SEMED</Typography>
          <Typography textAlign={'center'} color='error'>Preencha todos os campos obrigatórios (*)</Typography>
          <Paper name='--------------------------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px' }}>
            <Typography variant="h4" textAlign={'center'}>Dados da Criança</Typography>
            <Box display={'flex'} flexDirection={'row'} gap={'15px'}>
              <TextField
                label="Nome da Criança"
                name="nomeCrianca"
                value={formData.nomeCrianca}
                onChange={handleChange}
                onBlur={handleValidation}
                width='60%'
                margin="normal"
                required
                sx={{ flexGrow: '.8' }}
              />
              <TextField
                label="Data de Nascimento (da criança)"
                name="dataNascimentoCrianca"
                type="date"
                value={formData.dataNascimentoCrianca}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                width='40%'
                required
                sx={{ flexGrow: '.2' }}
              />
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap={'15px'}>
              <TextField
                label="Nome completo da Mãe"
                name="nomeMae"
                value={formData.nomeMae}
                onChange={handleChange}
                onBlur={handleValidation}
                width='60%'
                margin="normal"
                required
                sx={{ flexGrow: '.8' }}
              />
              <TextField
                label="Data de nascimento (da mãe)"
                name="dataNascimentoMae"
                type="date"
                value={formData.dataNascimentoMae}
                onChange={handleChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                width='40%'
                required
                sx={{ flexGrow: '.2' }}
              />
            </Box>
            <TextField
              label="Nome completo do Pai"
              name="nomePai"
              value={formData.nomePai}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel variant='filled'>Indique qual a creche que deseja a vaga</InputLabel>
              <Select
                name="crecheDesejada"
                value={formData.crecheDesejada}
                onChange={handleChange}
                onBlur={handleValidation}
                required
              >
                <MenuItem value="UMEI MELQUIADES LIMA">UMEI MELQUIADES LIMA</MenuItem>
                <MenuItem value="CMEI BERÇO DA LIBERDADE">CMEI BERÇO DA LIBERDADE</MenuItem>
                <MenuItem value="CMEI FLORESCER">CMEI FLORESCER</MenuItem>
                <MenuItem value="CMEI PROFESSORA IZAURA DE QUEIROZ">CMEI PROFESSORA IZAURA DE QUEIROZ</MenuItem>
                <MenuItem value="CMEI JARDIM DAS JURITIS">CMEI JARDIM DAS JURITIS</MenuItem>
                <MenuItem value="UPEIF SAGRADO CORAÇÃO DE JESUS">UPEIF SAGRADO CORAÇÃO DE JESUS</MenuItem>
                <MenuItem value="CRECHE MURININ">CRECHE MURININ</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant='filled'>A criança possui algum irmão matriculado na mesma Unidade ou Centro de Educação Infantil?</InputLabel>
              <Select
                name="irmaoMatriculado"
                value={formData.irmaoMatriculado}
                onChange={handleChange}
                required
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="não">Não</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant='filled'>Criança possui deficiência, transtornos globais do desenvolvimento ou altas habilidades / superdotação com laudo?</InputLabel>
              <Select
                name="criancaDeficiencia"
                value={formData.criancaDeficiencia}
                onChange={handleChange}
                required
              >
                <MenuItem value="sim">Sim</MenuItem>
                <MenuItem value="não">Não</MenuItem>
              </Select>
            </FormControl>
            {(formData.criancaDeficiencia == 'sim') && (
              <><FormControl fullWidth margin="normal">
                <InputLabel variant='filled'>Indique o tipo de deficiência da crianca</InputLabel>
                <Select
                  name="tipoDeficiencia"
                  value={formData.tipoDeficiencia}
                  onChange={handleChange}
                >
                  <MenuItem value="Transtorno do Espectro Autista">Transtorno do Espectro Autista</MenuItem>
                  <MenuItem value="Cegueira">Cegueira</MenuItem>
                  <MenuItem value="Baixa visão">Baixa visão</MenuItem>
                  <MenuItem value="Surdez">Surdez</MenuItem>
                  <MenuItem value="Altas habilidades / Superdotação">Altas habilidades / Superdotação</MenuItem>
                  <MenuItem value="Deficiência Física">Deficiência Física</MenuItem>
                  <MenuItem value="Deficiência Múltipla">Deficiência Múltipla</MenuItem>
                  <MenuItem value="Deficiência Auditiva">Deficiência Auditiva</MenuItem>
                  <MenuItem value="Deficiência Intelectual">Deficiência Intelectual</MenuItem>
                  <MenuItem value="Surdocegueira">Surdocegueira</MenuItem>
                </Select>
              </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel variant='filled'>Criança possui laudo?</InputLabel>
                  <Select
                    name="laudoDeficiencia"
                    value={formData.laudoDeficiencia}
                    onChange={handleChange}
                  >
                    <MenuItem value="sim">Sim</MenuItem>
                    <MenuItem value="não">Não</MenuItem>
                  </Select>
                </FormControl></>
            )}
          </Paper>
          {!isValidated && !isInit && (
            <Typography margin={'20px'} variant="h6" color={'error'} textAlign={'center'}>Dados já cadastrados anteriormente ou inválidos!</Typography>)}

          {isValidated && (
            <Box>
              <Paper name='--------------------------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>Dados do Responsável</Typography>
                <TextField
                  label="Nome do responsável pela criança"
                  name="nomeResponsavel"
                  value={formData.nomeResponsavel}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Grau de parentesco com a criança"
                  name="grauParentesco"
                  value={formData.grauParentesco}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Data de nascimento (do responsável)"
                  name="dataNascimentoResponsavel"
                  type="date"
                  value={formData.dataNascimentoResponsavel}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <TextField
                  label="Endereço completo"
                  name="endereco"
                  value={formData.endereco}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Bairro"
                  name="bairro"
                  value={formData.bairro}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Telefone de contato com DDD Ex.:(91) 99999-9999"
                  name="telefone"
                  value={formData.telefone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Paper>
              <Paper name='---------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>Dados Familiares</Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel>A família é beneficiária do Programa Bolsa Família?</InputLabel>
                  <Select
                    name="bolsaFamilia"
                    value={formData.bolsaFamilia}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="sim">Sim</MenuItem>
                    <MenuItem value="não">Não</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Qual a renda da Família? (Renda familiar = Soma de todas as rendas da casa. Valor em real)"
                  name="rendaFamiliar"
                  type="number"
                  value={formData.rendaFamiliar}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Com quem a criança reside?</InputLabel>
                  <Select
                    name="residenciaCrianca"
                    value={formData.residenciaCrianca}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Mãe somente">Mãe somente</MenuItem>
                    <MenuItem value="Pai somente">Pai somente</MenuItem>
                    <MenuItem value="Pai e mãe">Pai e mãe</MenuItem>
                    <MenuItem value="Outros">Outros</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Quantas pessoas moram na casa? (incluindo a criança)"
                  name="pessoasCasa"
                  type="number"
                  value={formData.pessoasCasa}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Situação dos pais e/ou responsável</InputLabel>
                  <Select
                    name="situacaoPais"
                    value={formData.situacaoPais}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Mãe trabalha">Mãe trabalha</MenuItem>
                    <MenuItem value="Pai trabalha">Pai trabalha</MenuItem>
                    <MenuItem value="Mãe e pai trabalham">Mãe e pai trabalham</MenuItem>
                    <MenuItem value="Mãe e pai não trabalham">Mãe e pai não trabalham</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
              <Paper name='---------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px', backgroundColor: '#ff923b', color: '#ffffff' }}>
                <Typography variant="h5" gutterBottom>Veracidade das Informações</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="veracidadeInformacoes"
                      checked={formData.veracidadeInformacoes}
                      onChange={handleChange}
                      sx={{ color: '#ffffff', '& .MuiCheckbox-root': { color: '#ff923b' } }}
                      required
                    />
                  }
                  label="Li e atesto que todas as informações acima são verdadeiras e que irei apresentá-las no momento da matrícula."
                />
              </Paper>
              <Box alignContent={'center'} margin={'20px'} display={'flex'} flexDirection={'column'}>
                <Button width='50%' type="submit" variant="contained" sx={{ backgroundColor: '#ff923b', color: '#fff', '&:hover': { backgroundColor: '#e76319' } }}>ENVIAR FORMULÁRIO</Button>
              </Box>
            </Box>)}
        </form>)}
      {isInsErr && (<Paper name='--------------------------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px' }}>
        <Typography variant="h2" textAlign={'center'} color={'error'}>Erro na inserção dos dados!</Typography>
      </Paper>
      )}
      {isInsOK && (
        <Box>
          <Paper name='--------------------------------------------' elevation={2} sx={{ padding: '20px', gap: '15px', marginTop: '20px', width: '90vw' }}>
            <Typography variant="h2" textAlign={'center'}>Cadastro para creche 2025</Typography>
            <Typography variant="h5" textAlign={'center'}>Cadastro Realizado com Sucesso!</Typography>
            <Typography variant="h5" textAlign={'center'}>Nº de Controle : {respCod}</Typography>
            <Typography textAlign={'center'} color='error'>Guarde o número de controle para o momento da matrícula</Typography>
          </Paper>
        </Box>
      )}

    </Box>
  );
};

export default FormularioCreche;
